import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SwiperCore, { Navigation, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import styled from "styled-components"
// import BackgroundImage from 'gatsby-background-image'

// import BlocksContent from "../blocksContentImage"
import BlocksContent from "../../components/blocksContent"
import "./loginContent.scss"

const Button = styled.a`
  ${({ fontColor }) =>
    fontColor &&
    `
   color: ${fontColor} !important;
   border: 1px solid ${fontColor} !important;
   
   `};
  ${({ alignment }) =>
    alignment &&
    `
        margin: 0px auto;
     `}
`

const StyledFeatureImage = styled(GatsbyImage)`
  width: 50%;
  // height: 250px;
  margin: 0px auto;
  // margin-bottom: 50px;
  padding-top: 10%;
`

const StyledBackgroundDiv = styled.div`
  ${({ backgroundImage }) =>
    backgroundImage && `background-image: url("${backgroundImage}")`};
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`};
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const StyledFlexWrapper = styled.div`
  ${({ fontColor }) =>
    fontColor &&
    `
   color: ${fontColor} !important;
    p, h1, h2, h3, h4, h5, h6, strong, span, a {
     
      color: ${fontColor} !important;
  }`}

  margin-top: auto;
  margin-bottom: auto;
`

SwiperCore.use([Navigation, Autoplay])

const LoginContent = () => {
  const { sanityLoginSlider } = useStaticQuery(graphql`
    {
      sanityLoginSlider {
        id
        loginSlider {
          _key
          slideText {
            _key
            _type
            _rawColumnContent
          }
          slideImage {
            _key
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          slideColor {
            hex
          }
          slideTextColor {
            hex
          }
          slideButtonUrl
          slideButtonText
          _key
          alignment
          slideFeaturedImage {
            _key
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        _key
      }
    }
  `)

  if (!sanityLoginSlider) {
    return null
  }

  const { loginSlider } = sanityLoginSlider

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoPlay: true,
    slidesToScroll: 1,
    arrows: true,
    autoplaySpeed: 500,
    autoPlay: true,
    useTransform: true,
    centerMode: true,
    cssEase: "linear",
  }

  return (
    <div className="slider-container" id="login-page">
      <Swiper
        className="swiper"
        spaceBetween={0}
        slidesPerView={1}
        navigation={{ nextEl: ".right", prevEl: ".left" }}
        loop={loginSlider.length > 1 ? true : false}
        autoplay={{ delay: 10000 }}
      >
        {loginSlider.map(slide => {
          return (
            <SwiperSlide key={slide._key}>
              <div className="slider-container" key={slide._key}>
                <div className="slide-wrapper">
                  <StyledBackgroundDiv
                    backgroundImage={
                      slide.slideImage &&
                      slide.slideImage.asset.gatsbyImageData.src
                    }
                    backgroundColor={
                      slide.slideColor &&
                      slide.slideColor.hex &&
                      slide.slideColor.hex
                    }
                  >
                    <StyledFlexWrapper fontColor={slide.slideTextColor.hex}>
                      {slide.slideFeaturedImage && (
                        <StyledFeatureImage
                          fluid={slide.slideFeaturedImage.asset.gatsbyImageData}
                          imgStyle={{ objectFit: "contain" }}
                        />
                      )}
                      {slide.slideText && (
                        <BlocksContent
                          blocks={slide.slideText._rawColumnContent}
                        />
                      )}
                      {slide.slideButtonText && (
                        <Button
                          fontColor={slide.slideTextColor.hex}
                          className="button"
                          href={slide.slideButtonUrl}
                          alignment={slide.alignment}
                          target="blank"
                        >
                          {slide.slideButtonText}
                        </Button>
                      )}
                    </StyledFlexWrapper>
                  </StyledBackgroundDiv>
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default LoginContent
