import React from "react"
import LayOut from "../components/layout"
import Header from "../components/legal/header/"
import SEO from "../components/legal/seo"
import LoginContent from "../components/login/LoginContent"

const Login = () => {
  return (
    <>
      <title>Login</title>
      <LoginContent />
    </>
  )
}

export default Login
